<template>
    <div>
        <div class="row mt-2 pr-3 py-2 font">
            <div class="col-12">
                <div class="row mt-1">
                    <div class="col-lg-2 pl-5 pl-lg-2">
                        <label class="w-100 text-right text-dark-blue mb-0">از تاریخ</label>
                        <input type="text" class="datepicker w-100 back-light py-1" v-model="start_date" id="start_date" placeholder="از تاریخ">
                        <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
                    </div>
                    <div class="col-lg-2 pl-5 pl-lg-2 mt-1 mt-lg-0">
                        <label class="w-100 text-right text-dark-blue mb-0">تا تاریخ</label>
                        <input type="text" class="datepicker w-100 back-light py-1" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                        <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
                    </div>
                    <div class="col-lg-3 pl-5 pl-lg-2 mt-1 mt-lg-0 text-right" v-if="!isAgent">
                        <label class="w-100 text-dark-blue mb-0">کارگزار</label>
                        <div class="select-container pb-1 pl-1" @click="showAgentOptions()" id="agentmultipleselect">
                            <span v-for="(item,index) in agentFilters" :key="index" class="selected px-2 pb-1 text-nowrap d-inline-block mt-1 mr-1 text-12">
                                <span v-for="(agent,j) in AGENTS" :key="j">
                                    <i v-if="item == agent.id" @click="removeAgent(index)" class="fas fa-close text-danger mt-1"></i>
                                    <span v-if="item == agent.id" class="mr-1">{{agent.name}}</span>
                                </span>
                            </span>
                        </div>
                        <div v-if="showAgents" class="option-container">
                            <div v-for="(item,index) in AGENTS" :key="index" @click="agentFilterClick(index)" class="option py-1 px-2">{{item.name}}</div>
                        </div>
                        <!-- <button v-for="(item,index) in AGENTS" :key="index" @click="agentFilterClick(index)" class="filter-unselect-blue mx-1" :class="{'agent-active':agent_active_list[index]}">{{item.name}}</button> -->
                    </div>
                    <div class="col-lg-3 pl-5 pl-lg-2 mt-1 mt-lg-0 text-right">
                        <label class="w-100 text-dark-blue mb-0">وضعیت توکن</label>
                        <div class="select-container pb-1 pl-1" @click="showStateOptions()" id="statemultipleselect">
                            <span v-for="(item,index) in stateFilters" :key="index" class="selected px-2 pb-1 text-nowrap d-inline-block mt-1 mr-1 text-12">
                                <span v-for="(state,j) in STATES" :key="j">
                                    <i v-if="item == state.id" @click="removeState(index)" class="fas fa-close text-danger mt-1"></i>
                                    <span v-if="item == state.id" class="mr-1">{{state.title}}</span>
                                </span>
                            </span>
                        </div>
                        <div v-if="showStates" class="option-container">
                            <div v-for="(item,index) in STATES" :key="index" @click="stateFilterClick(index)" class="option py-1 px-2">{{item.title}}</div>
                        </div>
                        <!-- <button v-for="(item,index) in AGENTS" :key="index" @click="agentFilterClick(index)" class="filter-unselect-blue mx-1" :class="{'agent-active':agent_active_list[index]}">{{item.name}}</button> -->
                    </div>
                    <div class="col-lg-2 text-right mt-1 mt-lg-0 pl-5 pl-lg-4">
                        <label class="w-100 text-dark-blue mb-0">نوع توکن</label>
                        <div class="select-container pb-1 pl-1" @click="showTypeOptions()" id="typemultipleselect">
                            <span v-for="(item,index) in typeFilters" :key="index" class="selected px-2 pb-1 text-nowrap d-inline-block mt-1 mr-1 text-12">
                                <span v-for="(type,j) in TYPES" :key="j">
                                    <i v-if="item == type.id" @click="removeType(index)" class="fas fa-close text-danger mt-1"></i>
                                    <span v-if="item == type.id" class="mr-1">{{type.title}}</span>
                                </span>
                            </span>
                        </div>
                        <div v-if="showTypes" class="option-container">
                            <div v-for="(item,index) in TYPES" :key="index" @click="typeFilterClick(index)" class="option py-1 px-2">{{item.title}}</div>
                        </div>
                        <!-- <button v-for="(item,index) in AGENTS" :key="index" @click="agentFilterClick(index)" class="filter-unselect-blue mx-1" :class="{'agent-active':agent_active_list[index]}">{{item.name}}</button> -->
                    </div>
                    <div class="col-lg-2 text-right mt-1 mt-lg-0 pl-5 pl-lg-4">
                        <label class="w-100 text-dark-blue mb-0">وضعیت ارزیابی</label>
                        <div class="select-container pb-1 pl-1" @click="showStatusOptions()" id="statusmultipleselect">
                            <span v-for="(item,index) in statusFilters" :key="index" class="selected px-2 pb-1 text-nowrap d-inline-block mt-1 mr-1 text-12">
                                <span v-for="(status,j) in STATUSES" :key="j">
                                    <i v-if="item == status.id" @click="removeStatus(index)" class="fas fa-close text-danger mt-1"></i>
                                    <span v-if="item == status.id" class="mr-1">{{status.title}}</span>
                                </span>
                            </span>
                        </div>
                        <div v-if="showStatuses" class="option-container">
                            <div v-for="(item,index) in STATUSES" :key="index" @click="statusFilterClick(index)" class="option py-1 px-2">{{item.title}}</div>
                        </div>
                    </div>
                    <div class="col-lg-2 text-right">
                        <div class="w-100">
                            <input v-model="is_assessmented" type="checkbox" class="checkbox mt-4">
                            <label class="text-dark-blue mr-2 position-relative">ارزیابی شده</label>
                        </div>
                        <div class="w-100">
                            <input v-model="agent_disagree" type="checkbox" class="checkbox">
                            <label class="text-dark-blue mr-2 position-relative bottom-4">اعتراض کارگزار</label>
                        </div>
                    </div>
                    <div class="col-lg-2 text-right">
                    </div>
                </div>
                <div class="row mt-3" dir="ltr">
                    <div class="col-xl-1 col-4 col-md-2 mt-1 mt-lg-0">
                        <button class="btn-dark-blue float-left py-1 px-3 ml-4 text-nowrap" @click="applyFilter()">اعمال فیلتر</button>
                    </div>
                    <div class="col-xl-2 col-4 col-md-3 mt-1 mt-lg-0">
                        <button class="btn-dark-blue float-left py-1 px-3 ml-4 text-nowrap" @click="exportExcel()">خروجی اکسل</button>
                    </div>
                    <div class="col-xl-5"></div>
                    <div class="col-xl-4">
                        <input type="text" v-model="searchInput" name="search" class="search-box float-right w-75 py-1 px-1" dir="rtl" placeholder="جستجو ...">
                        <button class="btn-search float-right py-1 px-2 mr-2" @click="search()">
                            <md-icon class="text-white">search</md-icon>
                        </button>
                    </div> 
                </div>
            </div>
        </div>
        <div class="row mt-2 font text-14">
            <div class="col-12 px-4 pl-5 overflow-auto">
                <div class="border-top-radius table text-center table-hover">
                    <thead class="back-light-blue">
                        <tr>
                            <th>نام کارگزار</th>
                            <th>توکن درخواست</th>
                            <th>تاریخ درخواست</th>
                            <th>نوع درخواست</th>
                            <th>وضعیت درخواست</th>
                            <!-- <th>نتیحه ارزیابی</th> -->
                            <th>نوع خطا</th>
                            <th>کد ارزیاب کارگزار</th>
                            <th>آی پی کاربر</th>
                            <th>جنسیت</th>
                            <th>سال تولد کاربر</th>
                            <th>وضعیت ارزیابی</th>
                        </tr>
                    </thead>
                    <tbody v-for="(item, index) in showList" :key="index" class="text-center text-dark-blue bg-white">
                        <tr @click="showDetails(index)">
                            <td v-if="item != null">
                                <label class="txt-primary weight-700">
                                    <span v-for="(agent, j) in AGENTS">
                                        <span v-if="item.agent == agent.id">{{agent.name}}</span>
                                    </span>
                                 </label>
                            </td>
                            <td v-if="item != null">
                                <label class="txt-primary">{{item.token}}</label>
                            </td>
                            <td v-if="item != null">
                                <label class="txt-primary text-sm">{{item.created}}</label>
                            </td>
                            <td v-if="item != null">
                                <label class="txt-primary text-sm">
                                    <span v-if="item.token_type==1">ثبت نام</span>
                                    <span v-else>ویرایش</span>
                                </label>
                            </td>
                            <td v-if="item != null">
                                <label class="txt-primary text-sm">
                                    <span v-for="(state, j) in STATES">
                                        <span v-if="item.token_state == state.id">{{state.title}}</span>
                                    </span>
                                </label>
                            </td>
                            <!-- <td>
                                <label class="txt-primary text-sm">
                                    <span v-for="(result, j) in RESULTS">
                                        <span v-if="item.token.label == result.id">{{resul.title}}</span>
                                    </span>
                                </label>
                            </td> -->
                            <td v-if="item != null">
                                <span v-for="(e, j) in item.error_code">{{ e, }}</span>
                            </td>
                            <td v-if="item != null">{{item.evaluator_National}}</td>
                            <td v-if="item != null">{{item.ip_user}}</td>
                            <td v-if="item != null">{{item.sex_user}}</td>
                            <td v-if="item != null">{{item.birth_year}}</td>
                            <td v-if="item != null">
                                <span v-if="item.is_assessmented" class="border-info bg-info text-white pt-40 pb-2 shadow px-2 text-12 font text-nowrap">ارزیابی شده</span>
                            </td>
                        </tr>
                        <tr v-if="details[index]">
                            <td colspan="2">
                                <img :src="personImage" class="d-inline float-right mr-4 border" style="width:160px !important; height:190px !important;" >
                            </td>
                            <td colspan="3">
                                <img :src="nationalImage" class="d-inline float-right mr-2 border" style="width:160px !important; height:190px !important;" >
                            </td>
                            <td colspan="3">
                                <video controls class="d-inline float-right mr-2 border" style="width:253px !important; height:190px !important;">
                                    <source :src="personVideo">
                                </video>
                            </td>
                            <td colspan="2">
                                <span v-if="isAgent" class="d-block pt-3 float-right w-100">
                                    <input type="checkbox" :disabled="item.final_view" class="float-right" v-model="item.agent_disagree">
                                    <label class="mr-1 float-right">مخالفم</label>
                                    <button v-if="!item.final_view" class="btn btn-success py-1 w-100" @click="agentDisagree(index)">ثبت</button>
                                </span>
                                <span v-else-if=" isSuperuser && !item.final_view && item.agent_disagree" class="d-block pt-4">
                                    <span class="text-nowrap text-danger">نظر کارگزار مخالف نظر ارزیاب است</span>
                                    <div class="w-100 mt-3">
                                        <div class="w-100">
                                            <input type="radio" :disabled="item.final_view" v-model="adminView" value="1" name="admin-view">
                                            <label class="mr-1">نظر کارگزار صحیح است</label>
                                        </div>
                                        <div class="w-100">
                                            <input type="radio" :disabled="item.final_view" v-model="adminView" value="2" name="admin-view">
                                            <label class="mr-1">نظر ارزیاب صحیح است</label>
                                        </div>
                                        <button v-if="!item.final_view" class="btn btn-success py-1 w-100" @click="FinalOpinion(index)">ثبت</button>
                                    </div>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </div>
            </div>
        </div>
        <div class="row mt-3 font pb-3" dir="ltr">
            <div class="col-3 col-lg-1 pl-5">
                <button class="sarshenas-btn-dark circle float-start" @click="prevPage()">❮</button>
            </div>
            <div class="col-4 col-lg-2 text-center text-dark-blue">
                <span>صفحه ی</span> <span>{{currentPage}}</span> <span>از</span> <span>{{numOfPage}}</span>
            </div>
            <div class="col-2 col-lg-1">
                <button class="sarshenas-btn-dark ml-1 circle float-" @click="nextPage()">❯</button>
            </div>
        </div>
        <!-- <input type="file" id="f" @change="readFile($event)"> -->
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'jalali-moment';
import auth from '../../services/authService';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})
export default {
    data(){
        return{
            AGENTS:[],
            STATES:[],
            STATUSES:[
                {
                    id:1,
                    title:'تایید کاذب'
                },
                {
                    id:2,
                    title:'رد کاذب'
                },
                {
                    id:3,
                    title:'بلاتکلیف'
                },
            ],
            TYPES:[
                {
                    id:1,
                    title:'ثبت نام'
                },
                {
                    id:2,
                    title:'ویرایش'
                }
            ],
            // agent_active_list:[false,false,false,false,false,false,false],
            // state_active_list:[false,false,false,false,false,false,false],
            // type_active_list:[false,false],
            agentFilters:[],
            stateFilters:[],
            typeFilters:[],
            statusFilters:[],
            start_date:'',
            end_date:'',
            details:[],
            currentPage:0,
            numOfPage:0,
            pageNumber:0,
            step:50,
            count:0,
            allData : [],
            showList:[],
            nextPageLink:'',
            prevPageLink:'',
            showAgents:false,
            showStates:false,
            showTypes:false,
            showStatuses:false,
            is_assessmented:false,
            agent_disagree:false,
            searchInput:'',
            personImage:'',
            nationalImage:'',
            personVideo:'',
            adminView:'',
            isSuperuser:false,
            isAgent:false,
            role:''
        }
    },
    methods:{
        showAgentOptions(){
            if(this.showAgents){
                this.showAgents = false
            }else{
                this.showAgents = true
                let agentIgnore = null
                if(!this.isAgent){
                    agentIgnore = document.getElementById('agentmultipleselect')
                }
                let stateIgnore = document.getElementById('statemultipleselect')
                let typeIgnore = document.getElementById('typemultipleselect')
                let statusIgnore = document.getElementById('statusmultipleselect')
            
                document.addEventListener('click', (event)=>{
                    let isInsideAgent = true
                    if(!this.isAgent){
                        isInsideAgent = agentIgnore.contains(event.target)
                    }
                    let isInsideState = stateIgnore.contains(event.target)
                    let isInsideStatus = statusIgnore.contains(event.target)
                    let isInsideType = typeIgnore.contains(event.target)
                    if(!isInsideAgent){
                        this.showAgents = false
                    }
                    if(!isInsideState){
                        this.showStates = false
                    }
                    if(!isInsideType){
                        this.showTypes = false
                    }
                    if(!isInsideStatus){
                        this.showStatuses = false
                    }
                })
            }
        },
        showStateOptions(){
            if(this.showStates){
                this.showStates = false
            }else{
                this.showStates = true
                let agentIgnore = null
                if(!this.isAgent){
                    agentIgnore = document.getElementById('agentmultipleselect')
                }
                let stateIgnore = document.getElementById('statemultipleselect')
                let typeIgnore = document.getElementById('typemultipleselect')
                let statusIgnore = document.getElementById('statusmultipleselect')
            
                document.addEventListener('click', (event)=>{
                    let isInsideAgent = true
                    if(!this.isAgent){
                        isInsideAgent = agentIgnore.contains(event.target)
                    }
                    let isInsideState = stateIgnore.contains(event.target)
                    let isInsideStatus = statusIgnore.contains(event.target)
                    let isInsideType = typeIgnore.contains(event.target)
                    if(!isInsideAgent){
                        this.showAgents = false
                    }
                    if(!isInsideState){
                        this.showStates = false
                    }
                    if(!isInsideType){
                        this.showTypes = false
                    }
                    if(!isInsideStatus){
                        this.showStatuses = false
                    }
                })
            }
        },
        showTypeOptions(){
            if(this.showTypes){
                this.showTypes = false
            }else{
                this.showTypes = true
                let agentIgnore = null
                if(!this.isAgent){
                    agentIgnore = document.getElementById('agentmultipleselect')
                }
                let stateIgnore = document.getElementById('statemultipleselect')
                let typeIgnore = document.getElementById('typemultipleselect')
                let statusIgnore = document.getElementById('statusmultipleselect')
            
                document.addEventListener('click', (event)=>{
                    let isInsideAgent = true
                    if(!this.isAgent){
                        isInsideAgent = agentIgnore.contains(event.target)
                    }
                    let isInsideState = stateIgnore.contains(event.target)
                    let isInsideStatus = statusIgnore.contains(event.target)
                    let isInsideType = typeIgnore.contains(event.target)
                    if(!isInsideAgent){
                        this.showAgents = false
                    }
                    if(!isInsideState){
                        this.showStates = false
                    }
                    if(!isInsideType){
                        this.showTypes = false
                    }
                    if(!isInsideStatus){
                        this.showStatuses = false
                    }
                })
            }
        },
        showStatusOptions(){
            if(this.showStatuses){
                this.showStatuses = false
            }else{
                this.showStatuses = true
                let agentIgnore = null
                if(!this.isAgent){
                    agentIgnore = document.getElementById('agentmultipleselect')
                }
                let stateIgnore = document.getElementById('statemultipleselect')
                let typeIgnore = document.getElementById('typemultipleselect')
                let statusIgnore = document.getElementById('statusmultipleselect')
            
                document.addEventListener('click', (event)=>{
                    let isInsideAgent = true
                    if(!this.isAgent){
                        isInsideAgent = agentIgnore.contains(event.target)
                    }
                    let isInsideState = stateIgnore.contains(event.target)
                    let isInsideStatus = statusIgnore.contains(event.target)
                    let isInsideType = typeIgnore.contains(event.target)
                    if(!isInsideAgent){
                        this.showAgents = false
                    }
                    if(!isInsideState){
                        this.showStates = false
                    }
                    if(!isInsideType){
                        this.showTypes = false
                    }
                    if(!isInsideStatus){
                        this.showStatuses = false
                    }
                })
            }
        },
        showDetails(index){
            if(this.showList[index].token_state == 8 || this.showList[index].token_state == 2){
                if(this.details[index]){
                    this.details[index] = false
                    this.details = JSON.parse(JSON.stringify(this.details))
                }else{
                    for (let i = 0; i < index; i++) {
                        this.details[i] = false
                    }
                    for (let i = index+1; i < this.details.length; i++) {
                        this.details[i] = false
                    }
                    const loading = this.$vs.loading({
                        type:'square',
                        text:'... در حال دریافت اطلاعات',
                    })
                    instance.get('/api/getagentmedia/?token=' + this.showList[index].token).
                    then(
                        response =>{
                            this.personImage=''
                            this.personVideo=''
                            this.nationalImage=''
                            if(response.status == 200 ){
                                this.details[index] = true;
                                this.details = JSON.parse(JSON.stringify(this.details))
                                this.personVideo = response.data.data.person_video
                                this.personImage = response.data.data.person_image
                                this.nationalImage = response.data.data.national_image
                                loading.close()
                            }
                        }
                    ).catch(
                        error => {
                            loading.close() 
                            this.showNotif('دریافت اطلاعات با مشکل مواجه شد','','danger')
                        }
                    )
                }
            }
         },
        agentFilterClick(index){
            let flag = false
            if(this.agentFilters.length!=0){
                for (let i = 0; i < this.agentFilters.length; i++) {
                    if(this.agentFilters[i] == this.AGENTS[index].id){
                        flag = true
                        break;
                    }
                }
                if(!flag){
                    this.agentFilters.push(this.AGENTS[index].id)
                }
            }
            else{
                this.agentFilters.push(this.AGENTS[index].id)
            }
        },
        stateFilterClick(index){
            if(this.statusFilters.length == 0){
                let flag = false
                if(this.stateFilters.length!=0){
                    for (let i = 0; i < this.stateFilters.length; i++) {
                        if(this.stateFilters[i] == this.STATES[index].id){
                            flag = true
                            break;
                        }
                    }
                    if(!flag){
                        this.stateFilters.push(this.STATES[index].id)
                    }
                }
                else{
                    this.stateFilters.push(this.STATES[index].id)
                }
            }else{
                this.showNotif('امکان اعمال همزمان فیلترهای وضعیت توکن و وضعیت ارزیابی وجود ندارد','','danger')
            }
        },
        statusFilterClick(index){
            if(this.stateFilters.length == 0){
                let flag = false
                if(this.statusFilters.length!=0){
                    for (let i = 0; i < this.statusFilters.length; i++) {
                        if(this.statusFilters[i] == this.STATUSES[index].id){
                            flag = true
                            break;
                        }
                    }
                    if(!flag){
                        this.statusFilters.push(this.STATUSES[index].id)
                    }
                }
                else{
                    this.statusFilters.push(this.STATUSES[index].id)
                }
            }else{
                this.showNotif('امکان اعمال همزمان فیلترهای وضعیت توکن و وضعیت ارزیابی وجود ندارد','','danger')
            }
        },
        typeFilterClick(index){
             let flag = false
            if(this.typeFilters.length!=0){
                for (let i = 0; i < this.typeFilters.length; i++) {
                    if(this.typeFilters[i] == this.TYPES[index].id){
                        flag = true
                        break;
                    }
                }
                if(!flag){
                    this.typeFilters.push(this.TYPES[index].id)
                }
            }
            else{
                this.typeFilters.push(this.TYPES[index].id)
            }
        },
        removeAgent(index){
            this.agentFilters.splice(index, 1)
        },
        removeState(index){
            this.stateFilters.splice(index, 1)
        },
        removeType(index){
            this.typeFilters.splice(index,1)
        },
        removeStatus(index){
            this.statusFilters.splice(index,1)
        },
        prevPage(){
            if(this.prevPageLink != null){
                instance.get(this.prevPageLink).
                then(
                    response=>{
                        this.details=[]
                        this.showList= response.data.results
                        this.prevPageLink=response.data.previous
                        this.nextPageLink=response.data.next
                        this.currentPage--
                    }
                )
            }
        },
        nextPage(){
            if(this.nextPageLink != null){
                instance.get(this.nextPageLink).
                then(
                    response=>{
                        this.details=[]
                        this.showList= response.data.results
                        this.prevPageLink=response.data.previous
                        this.nextPageLink=response.data.next
                        this.currentPage++
                    }
                )
            }
        },
        pagination(count){
             if(count % this.step == 0){
                this.numOfPage = parseInt(count / this.step);
            }else{
                this.numOfPage = parseInt(count / this.step) + 1;
            }
        },
        getTokens(filter){
            return instance.get('/api/tokens/', {params:filter})
        },
        applyFilter(){
            const loading = this.$vs.loading({
                type:'square',
                text:'... در حال دریافت اطلاعات',
            })
            this.details = []
            let filter = {
                created_from:'',
                created_to:'',
                agent:'',
                token_state:'',
                token_type:'',
                label:'',
                assessment:false,
                agent_disagree:false,
                final_view:false
            }
            let sdate = moment.from(this.start_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            let edate= moment.from(this.end_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')

            filter.created_from=sdate 
            filter.created_to=edate
            this.agentFilters.length != 0 ? filter.agent = this.agentFilters.toString() : filter.agent=null
            this.stateFilters.length !=0 ? filter.token_state = this.stateFilters.toString() : filter.token_state=null
            this.typeFilters.length != 0 ? filter.token_type = this.typeFilters.toString() : filter.token_type = null
            this.statusFilters.lenght !=0 ? filter.label = this.statusFilters.toString() : filter.label=null
            filter.assessment = !this.is_assessmented
            filter.agent_disagree = this.agent_disagree
            // filter.final_view = 

            this.getTokens(filter).
            then(
                response=>{
                    if(response.status == 200){
                        this.count = response.data.count
                        this.showList = JSON.parse(JSON.stringify(response.data.results))
                        this.pagination(this.count)
                        this.prevPageLink=response.data.previous
                        this.nextPageLink=response.data.next
                        this.currentPage = 1
                    }
                }
            ).catch(
                error => {
                    if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
            if(this.showList.length != 0){
                setTimeout(() => {
                    loading.close()
                }, 1500);
            }else{
                setTimeout(() => {
                    loading.close()
                }, 2000);
            }
        },

        search(){
            let params = {
                search: this.searchInput
            }
            
            instance.get('api/tokens/', {params:params}).
            then(
                response =>{
                    this.count = response.data.count
                    this.showList = JSON.parse(JSON.stringify(response.data.results))
                    this.pagination(this.count)
                    this.prevPageLink=response.data.previous
                    this.nextPageLink=response.data.next
                }
            ).catch(
                error =>{
                    if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
        },

        exportExcel(){
            let filter = {
                start_date:'',
                end_date:'',
                agent_filter:[],
                state_filter:[],
                type_filter:[],
                opinion_filter:[],
                is_assessmented:false

            }
            filter.start_date = this.start_date
            filter.end_date = this.end_date
            filter.agent_filter= this.agentFilters
            filter.state_filter = this.stateFilters
            filter.type_filter = this.typeFilters
            filter.opinion_filter = this.statusFilters
            filter.is_assessmented = this.is_assessmented

            instance.post('/api/export/', filter, {responseType:"blob"}).
            then(
                response=>{
                    if(response.status == 200){
                        this.showNotif('درخواست شما با موفقیت ثبت شد','برای دریافت فایل گزارش به صفحه گزارشات بروید.','success')
                        // let filename = 'tokens.xlsx';
                        // // The actual download
                        // let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
                        // let link = document.createElement('a');
                        // link.href = window.URL.createObjectURL(blob);
                        // link.download = filename;

                        // document.body.appendChild(link);

                        // link.click();

                        // document.body.removeChild(link);
                    }
                }
            ).catch(
                error =>{
                    if (error.response.status == 404){
                        this.showNotif('برای بازه انتخابی اطلاعاتی یافت نشد.','','danger')
                    }
                    else if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
            // if(this.showList.length != 0){
            //     setTimeout(() => {
            //         loading.close()
            //     }, 1500);
            // }else{
            //     setTimeout(() => {
            //         loading.close()
            //     }, 2000);
            // }
            // this.showNotif('درخواست شما با موفقیت ثبت شد','برای دریافت فایل گزارش به صفحه گزارشات بروید.','success')
        },
        agentDisagree(index){
            if(this.showList[index].agent_disagree != null){
                let obj = {
                    token:this.showList[index].token,
                    agent_disagree:true
                }
                instance.put('api/tokens/', obj).then(
                    response =>{
                        if(response.status == 200){
                            this.showNotif('اعتراض با موفقیت ثبت شد', '', 'success')
                        }
                    }
                ).catch(
                    error=>{
                        if(error.response.status == 401){
                            auth.logout()
                        }
                    }
                )
            }else{
                this.showNotif('لطفا مخالفم را تایید کنید.', '', 'warning')
            }
        },
        FinalOpinion(index){
            if(this.adminView == '1'){
                let obj={
                    token:this.showList[index].token,
                    label:null,
                }
                instance.put('api/tokens/', obj).then(
                    response =>{
                        if(response.status == 200){
                            this.showNotif('ارزیابی نهایی با موفقیت انجام شد.','','success')
                        }
                    }
                ).catch(
                    error=>{
                        // this.showNotif('')
                    }
                )
            }else if(this.adminView == '2'){
                let obj={
                    token:this.showList[index].token,
                }
                instance.put('api/tokens/', obj).then(
                    response =>{
                        if(response.status == 200){
                            this.showNotif('ارزیابی نهایی با موفقیت انجام شد.','','success')
                        }
                    }
                ).catch(
                    error=>{}
                )
            }
        },
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
        getAgentList(){
            instance.get('/api/agent_list/').
            then(
                response =>{
                    if(response.status == 200 ){
                        this.AGENTS = JSON.parse(JSON.stringify(response.data))
                    }
                }
            )
        },
        getStateList(){
            instance.get('/api/state_list/').
            then(
                response =>{
                    if(response.status == 200 ){
                        this.STATES = JSON.parse(JSON.stringify(response.data))
                    }
                }
            )
        },
        showNotif(title, text, color){
            this.$vs.notification({
                title:title,
                text:text,
                border:color,
                position:'top-center',
            })
        }
    },
    mounted(){
        this.isAgent = localStorage.getItem('is_agent').toLowerCase() === 'true'
        this.isSuperuser = localStorage.getItem('is_superuser').toLowerCase() === 'true'
        let today = new Date()
        let yesterday = today.setDate(today.getDate()-2)
        let yesterday_jalali = this.convert_to_jalali(yesterday)
        this.start_date = yesterday_jalali
        this.end_date = yesterday_jalali
        this.pageNumber = 1
        this.getAgentList()
        this.getStateList()
        this.applyFilter()
    }
}
</script>
<style>
.back-light-blue{
    background-color: #00bcd4;
    color: #002e5b;
}
.text-dark-blue{
    color: #2f4b7c;
}
.font{
    font-family: 'IranSans';
}
.text-14{
    font-size: 14px;
}
.text-13{
    font-size: 13px;
}
.border-top-radius{
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}
.weight-700{
    font-weight: 700;
}
.hover:hover{
    box-shadow: 0 2px 4px 0 #aaaaaa;
}
.flex-col{
    flex-direction: column;
}
.textarea{
    border-radius: 0.5rem;
}
.circle{
    border-radius: 50%;
    width: 35px;
    height: 35px;
}
.sarshenas-btn-dark{
    background-color: #145da1;
    color: white;
    border: 1px solid transparent;
    border-radius: 1.5rem;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);;
    box-sizing: border-box;
    font-size: 12px;
}
.datepicker{
    border-radius: 0.5rem;
    box-shadow: 0x 2px 5px 1px #7b7b7b !important;
}
.width-auto{
    width: auto !important;
    /* overflow-x: scroll !important; */
}
.text-dark-blue{
    color: 145da1;
}
.btn-dark-blue{
    background-color: #145da1;
    border: 1px solid #145da1;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 6px -1px #8f9ee6;
    color: #fff;
    font-size: 14px;
}
.filter-unselect-blue{
    border: 1px solid #145da1;
    border-radius: 0.5rem;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #fff;
    color: #145da1;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 4px 6px -1px #8f9ee6;
}
.filter-unselect-pink{
    border: 1px solid #f95d6a;
    border-radius: 0.5rem;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #fff;
    color: #f95d6a;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 4px 6px -1px #fe92aa;
}
.filter-unselect-purple{
    border: 1px solid #a759ff;
    border-radius: 0.5rem;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #fff;
    color: #a759ff;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 4px 6px -1px #d495f1;
}
/* .filter-unselect-purple:hover{
    background-color: #a759ff;
    color: white;
}
.filter-unselect-blue:hover{
    background-color: #145da1;
    color: #fff;
}
.filter-unselect-pink:hover{
    background-color: #f95d6a;
    color: #fff;
} */
.bottom-6{
    bottom: 6px;
}
.back-light{
    background-color: #fff !important;
    color: #145da1 !important;
    border-color: #145da1 !important;
}
.agent-active{
    background-color: #145da1 !important;
    color: #fff !important;
}
.type-active{
    background-color: #f95d6a !important;
    color: #fff !important;
}
.state-active{
    background-color: #a759ff !important;
    color: #fff !important;
}
.select-container{
    width: 100%;
    min-height: 32px;
    max-height: auto;
    background-color: white;
    border: 1px solid #002e5b;
    border-radius: 0.5rem;
}
.option-container{
    position: absolute;
    z-index: 100;
    background-color: rgb(255, 255, 255) !important;
    width: 190px;
    margin-right: 0.1rem;
    box-shadow: 1px 2px 8px 0px rgb(169, 168, 168);
}
.option:hover{
    background-color: #dddddd;
    cursor: pointer;
}
.selected{
    background-color: #b0dadf;
    border:1px solid #00bcd4;
    border-radius: 0.8rem;
    color: #145da1;
}
.text-12{
    font-size: 12px;
}
.bottom-4{
    bottom: 4px;
}
.border-radius{
    border-radius: 0.5rem;
}
.checkbox{
    width: 20px;
    height: 20px;
}
.vs-notification__content__header h4{
    font-family: 'IranSans' !important;
    text-align: right !important;
    padding-right: 15px !important;
}
.search-box{
    border: 1px solid #002e5b;
    border-radius: 0.5rem;
}
.btn-search{
    background-color: #145da1;
    border-radius: 0.5rem;
    border-color: #145da1;
}
.bg-info{
    background-color: #0082fe !important;
}
.border-info{
    border: solid 1px #0082fe !important;
    border-radius: 1.5rem !important;
}
.shadow{
    box-shadow: 1px 5px 8px 0px #aaaaaa !important;
}
.pt-40{
    padding-top: 0.4rem;
}
</style>